<template>
    <div class="activity-card-table__wrapper">
        <b-card no-body>
            <b-card-header>Activity log</b-card-header>

            <b-card-body>
                <br />

                <b-row>
                    <b-col md="3">
                        <wit-select
                            placeholder="Resource"
                            v-model="filters.resource"
                            :options="filterOptions.resources"
                            @input="changeFilter"
                        ></wit-select>
                    </b-col>

                    <b-col md="3">
                        <wit-select
                            placeholder="Resource Type"
                            v-model="filters.resourceType"
                            :options="filterOptions.types"
                            @input="changeFilter"
                        ></wit-select>
                    </b-col>

                    <b-col md="3">
                        <wit-select
                            placeholder="Resource Subtype"
                            v-model="filters.resourceSubtype"
                            :options="filterOptions.subtypes"
                            @input="changeFilter"
                        ></wit-select>
                    </b-col>

                    <b-col md="3">
                        <DateRangePicker
                            style="width: 100%"
                            ref="picker"
                            :appendToBody="true"
                            opens="left"
                            v-model="dateRange"
                            class="stats-filter-row__daterange-picker"
                            @update="changeFilter"
                        ></DateRangePicker>
                    </b-col>
                </b-row>

                <br />
                <b-row class="active-log HEADER">
                    <b-col v-for="header in headers" :key="header">
                        <h4>{{ header }}</h4>
                    </b-col>
                </b-row>
                <b-list-group v-for="(item, $index) in list" :key="$index">
                    <b-list-group-item class="active-log" :class="item.action">
                        <b-row>
                            <b-col>
                                <p>{{ new Date(item.createdAt).toDateString() }}</p>
                            </b-col>
                            <b-col>
                                <p>{{ item.user.email }}</p>
                            </b-col>
                            <b-col>
                                <p>{{ item.action }}</p>
                            </b-col>
                            <b-col>
                                <p>{{ item.resource }}</p>
                            </b-col>
                            <b-col>
                                <p>{{ item.resourceName }}</p>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-list-group>

                <br />

                <infinite-loading
                    forceUseInfiniteWrapper="activity-card-table__wrapper"
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                >
                </infinite-loading>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import Vue from 'vue'

import InfiniteLoading from 'vue-infinite-loading'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    components: {
        InfiniteLoading,
        DateRangePicker,
    },
    data() {
        return {
            headers: ['Date', 'User', 'Action', 'Resource', 'Name'],
            list: [],
            cursorId: null,
            infiniteId: +new Date(),
            apiUrl: `${process.env.VUE_APP_NODE_API_HOST}/activeLog`,
            filters: {
                resource: null,
                resourceType: null,
                resourceSubtype: null,
            },
            filterOptions: [],
            dateRange: {
                startDate: this.getWeekAgoDate(),
                endDate: new Date(),
            },
        }
    },

    async created() {
        const response = await this.axios.get(`${this.apiUrl}/filters`)

        this.filterOptions = response.data.data
    },

    methods: {
        infiniteHandler($state) {
            this.axios
                .get(this.apiUrl, {
                    params: {
                        projectId: this.$store.state.project.active.id,
                        limit: 10,
                        cursor: this.cursorId,
                        resource: this.filters.resource,
                        type: this.filters.resourceType,
                        subtype: this.filters.resourceSubtype,
                        start: this.dateRange.startDate,
                        end: this.dateRange.endDate,
                    },
                })
                .then(({data}) => {
                    if (this.cursorId !== data.data.newCursorId) {
                        this.cursorId = data.data.newCursorId
                        this.list.push(...data.data.queryData)
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
        },
        getWeekAgoDate() {
            const date = new Date()
            const weekAgo = date.getDate() - 7
            date.setDate(weekAgo)

            return date
        },
        changeFilter() {
            this.list = []
            this.cursorId = null
            this.infiniteId += 1
        },
    },
}
</script>

<style lang="scss">
.activity-card-table__toggle-details {
    margin-left: 15px;
}
.activity-card-table__details {
    padding: 0 1rem;
    * {
        background-color: #e4e5e6;
    }
    .activity-card-table__details-headers {
        th {
            text-align: center;
            padding-right: 3rem;
        }
    }
}

.active-log {
    border: #eaeaea solid 1px;
    margin: 10px 0;
    padding: 10px;
    border-left: #95effe solid 5px;
    position: relative;
    background: #fff;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
}

.active-log.HEADER {
    border: #eaeaea solid 2px;
}

.active-log.CREATE {
    border-left: green solid 5px;
}

.active-log.DELETE {
    border-left: red solid 5px;
}
</style>
