<template>
    <div class="animated fadeIn">
        <div class="activity">
            <activity-card></activity-card>
        </div>
    </div>
</template>

<script>
import ActivityCard from './ActivityCard.vue'

export default {
    components: {
        ActivityCard,
    },
}
</script>